import ReqError from "@source/api/reqError";
import { api } from "@source/api/http";
import { ICreateAnnouncementReq, ICreateAnnouncementResponse, IEditAnnouncementReq, IGetAnnouncementDocumentUploadLink, IGetAnnouncementPhotoUploadLink, IGetAnnouncementsResponse } from "../models/announcementsModels";

export default class AnnouncementsService {
  static async getAnnouncements(nursingHomeId: string) {
    try {
      return (await api.get<IGetAnnouncementsResponse>(`announcement/nursingHome/${nursingHomeId}`)).data
    } catch (e: any) {
      throw ReqError.getAnnouncements(e.response)
    }
  }
  
  static async createAnnouncement(req: FormData) {
    try {
      return (await api.post<ICreateAnnouncementResponse>(`announcement`, req)).data
    } catch (e: any) {
      throw ReqError.createAnnouncement(e.response)
    }
  }

  static async editAnnouncement(req: FormData, announcementId: string) {
    try {
      return (await api.patch(`announcement/${announcementId}`, req)).data
    } catch (e: any) {
      throw ReqError.createAnnouncement(e.response)
    }
  }

  static async getAnnouncementPhotoUploadLink(req: FormData, announcementId: string) {
    try {
      return (await api.patch(`announcement/${announcementId}/photo`, req)).data
    } catch (e: any) {
      throw ReqError.createAnnouncement(e.response)
    }
  }

  static async getAnnouncementDocumentUploadLink(req: IGetAnnouncementDocumentUploadLink, announcementId: string) {
    try {
      return (await api.patch(`announcement/${announcementId}/document`, {...req})).data
    } catch (e: any) {
      throw ReqError.createAnnouncement(e.response)
    }
  }

  static async deleteAnnouncementPhoto(announcementId: string) {
    try {
      return (await api.delete(`announcement/${announcementId}/photo/delete`)).data
    } catch (e: any) {
      throw ReqError.deleteAnnouncement(e.response)
    }
  }

  static async deleteAnnouncementDocument(announcementId: string) {
    try {
      return (await api.delete(`announcement/${announcementId}/document/delete`)).data
    } catch (e: any) {
      throw ReqError.deleteAnnouncement(e.response)
    }
  }

  static async deleteAnnouncement(announcementId: string) {
    try {
      return (await api.delete(`announcement/${announcementId}`)).data
    } catch (e: any) {
      throw ReqError.deleteAnnouncement(e.response)
    }
  }
}