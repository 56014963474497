import { useHistory } from "react-router-dom";
import styles from "./announcementsCard.module.scss";
import moment from "moment";
import announcementsStore from "@source/store/announcementsStore";
import iconPdf from "@source/assets/icons/iconPdf.svg";
import iconTxt from "@source/assets/icons/iconTxt.svg";
import Svg from "@components/UI/svg/svg";
import iconEditResident from "@source/assets/icons/iconEditResident.svg";
import iconDelete from "@source/assets/icons/iconDelete.svg";
import { HOME_ROUTES } from "@source/utils/utils";
import AnnouncementsController from "@source/api/controllers/announcementsController";
import useFetching from "@source/hooks/useFetching";
import Notification from "@components/snackMessage/notification";
import userStore from "@store/userStore";
import { HomePermissionsEnum } from "@source/api/models/permissionsModels";
import {AnnouncementFile, IAnnouncement} from "@source/api/models/announcementsModels";
import ConfirmModal from "@components/confirmModal/confirmModal";
import { useFirebaseLogEvent } from "@source/utils/hooks/useFirebaseLogEvent";
import nursingHomeStore from "@store/nursingHomeStore";

const AnnouncementsCard = (props: AnnouncementsCardProps) => {
  const { className, announcement } = props;
  const {user, photo, heading, description, createdDate} = announcement;

  const [deleteAnnouncement, deleteAnnouncementIsLoading, deleteAnnouncementError] = useFetching<ReturnType<typeof AnnouncementsController.deleteAnnouncement>>(AnnouncementsController.deleteAnnouncement);

  const router = useHistory();
  const [logEvent] = useFirebaseLogEvent();

  const onEditAnnouncement = () => {
    announcementsStore.setEditAnnouncement(announcement);
    router.push(HOME_ROUTES.NewAnnouncement);
  }

  const onDeleteAnnouncement = async () => {
    const deleteAnnouncementRes = await deleteAnnouncement(announcement._id);

    if (deleteAnnouncementRes) {
      logEvent('announcements__delete', {
        home: nursingHomeStore.getActiveNursingHome()?.name || "",
        organization: nursingHomeStore.getActiveNursingHome()?.organization.name || "",
      })
      announcementsStore.deleteAnnouncement(announcement._id);
      new Notification().success('Announcement has been deleted');
    }
  }

  function downloadURI(file: AnnouncementFile) {
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = file.locationLink;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  return (
    <div className={`${styles.announcementsCard} ${className}`}>
      <div className={styles.info}>
        <div className={styles.head}>
          <span className={styles.text}>
            {user.firstName} {user.lastName}
          </span>

          {
            createdDate ?
              <>
                <div className={styles.dot} />

                <span className={styles.text}>
                  {moment(createdDate).format("DD MMMM YYYY")}
                </span>
              </>
              :
              <></>
          }
          
          {
            userStore.isPermission(HomePermissionsEnum.EDIT_ANNOUNCEMENT_EVENT) ?
              <div className={styles.adminActions}>
                <div className={styles.editAnnouncement} onClick={onEditAnnouncement}>
                  <Svg
                    className={styles.icon}
                    src={iconEditResident}
                    width={24}
                    height={24}
                    color="#3956C7"
                  />

                  <span className={styles.text}>Edit</span>
                </div>

                <ConfirmModal
                  confirmCb={() => onDeleteAnnouncement()}
                  className={styles.deleteAnnouncement}
                  title="Are you sure you want to delete the announcement?"
                  confirmLoading={deleteAnnouncementIsLoading}
                >
                 <Svg
                    className={styles.icon}
                    src={iconDelete}
                    width={24}
                    height={24}
                    color="#DB1818"
                  />

                  <span className={styles.text}>Delete</span>
                </ConfirmModal>
              </div>
              :
              <></>
          }
        </div>
        <div className={styles.body}>
          <span className={styles.title}>{heading}</span>

          <span className={styles.text}>{description}</span>

          {
            announcement.files && announcement.files.length ?
                <div className={styles.documents}>
                  {
                    announcement.files.map((file: AnnouncementFile) => {
                      if (file.fileName.includes('.pdf')) {
                        return (
                            <div key={file.key} className={styles.document} onClick={() => {downloadURI(file)}}>
                              <img
                                  className={styles.icon}
                                  src={iconPdf}
                                  alt="document"
                              />

                              <span className={styles.text}>{file.fileName}</span>
                            </div>
                        )
                      }

                      return null;
                    })
                  }
                </div>
                :
                <></>
          }

          {
            announcement.files && announcement.files.length ?
                <div className={styles.photos}>
                  {
                    announcement.files.map((file: AnnouncementFile) => {
                      if (!file.fileName.includes('.pdf')) {
                        return (
                            <img
                                key={file.key}
                                className={styles.documentPhoto}
                                src={file.locationLink}
                                alt="photo"
                            />
                        )
                      }

                      return null;
                    })
                  }
                </div>
                :
                <></>
          }
        </div>
      </div>

{/*       {
        isLoading
          ? <div className={styles.visual}></div>
          : cachePhoto 
            ? <div className={styles.visual}>
                <img className={styles.picture} src={cachePhoto} alt="announcementImg" />
              </div>
            : <></>
      } */}
    </div>
  );
};

export default AnnouncementsCard;

interface AnnouncementsCardProps {
  className: string;
  key: string;
  announcement: IAnnouncement;
}
